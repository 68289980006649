import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from "react-bootstrap-sweetalert";

const EsignConfirmation = props => {

  return <SweetAlert
    customClass={"esign-confirm-alert"}
    title="Your E-Signature is needed. You consent to signing this document electronically."
    reverseButtons={true}
    customButtons={
      <React.Fragment>
        <button onClick={props.onConfirm} className="btn e-sign-confirm-btn">Agree </button>
        <button onClick={props.onCancel} className="btn e-sign-cancel-btn">
          Cancel
        </button>
      </React.Fragment>
    }
    {...props}>
    {props.children}
  </SweetAlert>
}

EsignConfirmation.propTypes = {
  children: PropTypes.any,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default EsignConfirmation;