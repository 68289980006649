import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { isMobile, isBrowser, isTablet } from 'react-device-detect';
import DesktopContent from "./DesktopContent";
import MobileContent from "./MobileContent";
import AppointmentContext from "context/appointment";

const Index = () => {

  return <AppointmentContext>
    <MetaTitle>Set an appointment</MetaTitle>
    {/* Tablet View */}
    {isTablet && <MobileContent />}
    {/* Browser and not Tablet View */}
    {isBrowser && !isTablet && <div className="h-100"><DesktopContent /></div>}
    {/* Mobile and not Tablet View */}
    {isMobile && !isTablet && <MobileContent />}
  </AppointmentContext>
}

export default Index;
