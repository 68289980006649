import React from 'react';
import { Button } from 'reactstrap';
import { useDocViewer } from './Context';
import { isTablet } from 'react-device-detect';

const DocViewerFooter = () => {

  /**
   * Context vars (see: Context.js)
   */
  const { isSignBusy, signDoc, canSign, readOnly, enableSigning, addedFields, currentSignerId } = useDocViewer();

  /**
   * Checks whether we need to show the footer at all
   * @returns {boolean}
   */
  const hasFooter = () => readOnly || canSign();

  const targetFields = addedFields.filter(f => f.signerId == currentSignerId);

  const signedFields = targetFields.filter(f => !!f.signature);

  const isSigningComplete = signedFields.length == targetFields.length;

  return < React.Fragment >
    {hasFooter() && <div className="doc-viewer-footer">
      {readOnly && <>
        {
          !isTablet &&
          <div className="e-sign-doc-info">
            <div className="info-icon"><i className="fas fa-info-circle"></i></div>
            <div className="info-text">
              Before you begin the e-signing process please review the contract carefully to make
              sure all the terms of the agreement are clear. Once ready, click Sign Contract. <i className="fas fa-arrow-right arrow-icon"></i>
            </div>
          </div>
        }
        <Button type="button" color="primary" onClick={enableSigning}>Sign contract</Button>
      </>
      }
      {!readOnly && canSign() &&
        <Button type="button" color="primary" onClick={signDoc} disabled={isSignBusy}>
          {isSignBusy && <i className="mdi mdi-spin mdi-loading me-1" />}
          {<span><span className="me-2">{signedFields.length} / {targetFields.length}</span> {isSigningComplete ? 'Complete Signing' : 'Signatures'}</span>}
        </Button>}
    </div>
    }
  </React.Fragment >
}

export default DocViewerFooter;