import React, { useState } from "react";
import DocViewer from 'components/Shared/DocViewer';
import PropTypes from "prop-types";
import { useAuth } from "context/auth";
import EsignConfirmation from "../Partial/EsignConfirmation";

const Sign = ({ id, goBack }) => {

  const { user: authUser } = useAuth();

  // by default, hide the signature fields so the user can read the doc
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  return (
    <React.Fragment>
      <DocViewer
        docId={id}
        currentSignerId={authUser.id}
        readOnly={isReadOnly}
        onSigningStarted={() => setIsConfirmationVisible(true)}
        onViewerClosed={() => goBack()}
        onSigningComplete={() => goBack()}
        onDocLoaded={doc => doc.signingId ? setIsReadOnly(false) : setIsReadOnly(true)} />
      {isConfirmationVisible && <EsignConfirmation
        onConfirm={() => {
          setIsConfirmationVisible(false);
          setIsReadOnly(false);
        }}
        onCancel={() => { setIsConfirmationVisible(false); goBack() }}>
        <div>
          The parties agree that this Agreement and all notices and disclosures made or given in connection with this Agreement may be created, executed, delivered and retained electronically. As such, the parties agree that this Agreement and any related documents may be signed electronically, and that the electronic signatures appearing on this Agreement or any related documents shall have the same legal effect for all purposes, including validity,enforceability and admissibility, as a handwritten signature. If you are a car buyer, you may access a copy of your digital records by contacting<a href="mailto: cs@mavisgn.com"> cs@mavisgn.com</a>. You have the right to receive a copy of the electronic record by requesting a paper copy. To opt out of electronic signatures, stop immediately and  contact your dealership. Opting out of electronic signatures may delay your car purchase.
        </div>
      </EsignConfirmation>}
    </React.Fragment>
  )
}

Sign.propTypes = {
  id: PropTypes.number,
  goBack: PropTypes.func,
};

export default Sign;
