import React from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { isMobile, isBrowser, isTablet } from 'react-device-detect';
import DesktopContent from "./DesktopContent/DesktopContent";
import MobileContent from "./MobileContent/MobileContent";

const Index = () => {

  return <React.Fragment>
    <MetaTitle>Preview Docs</MetaTitle>
    {/* Tablet View */}
    {isTablet && <MobileContent />}
    {/* Browser and not Tablet View */}
    {isBrowser && !isTablet && <div className="h-100"><DesktopContent /></div>}
    {/* Mobile and not Tablet View */}
    {isMobile && !isTablet && <MobileContent />}
  </React.Fragment>
}

export default Index;
